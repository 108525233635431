import styled from "@emotion/styled";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import ErrorDialog from "@/components/dialogs/ErrorDialog";
import FormInput from "@/components/forms/FormInput";
import { useAuth } from "src/contexts/AuthContext";
import { theme } from "src/theme/theme";
import { TypeOf, literal, object, string } from "zod";
import { ROUTES } from "@/constants/routes";
import * as Sentry from "@sentry/nextjs";

export const LinkItem = styled(Link)`
  text-decoration: none;
  color: #3683dc;
  &:hover {
    text-decoration: underline;
    color: #5ea1b6;
  }
`;

const loginSchema = object({
  email: string().min(1, "Email is required").email("Email is invalid"),
  password: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters")
    .max(32, "Password must be less than 32 characters"),
  persistUser: literal(true).optional(),
});

type ILogin = TypeOf<typeof loginSchema>;

const ForgotPasswordContainer = styled("div")(() => ({}));

const FormHeader = styled(Typography)(() => ({
  textAlign: "left",
  marginBlockEnd: "3rem",
  color: "white",
  fontWeight: "lighter",
}));

const Login = () => {
  const router = useRouter();
  const { user, login } = useAuth();
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const defaultValues: ILogin = {
    email: "",
    password: "",
  };

  const methods = useForm<ILogin>({
    resolver: zodResolver(loginSchema),
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<ILogin> = async (values: ILogin) => {
    try {
      setLoadingState(true);
      setShowErrorDialog(false);
      await login(values.email, values.password);
      setLoadingState(false);
    } catch (err: any) {
      setLoadingState(false);
      setErrorTitle("Failed to login");
      setErrorMessage(err.message);
      setShowErrorDialog(true);
      Sentry.captureException(err);
    }
  };

  useEffect(() => {
    if (user) {
      router.push(ROUTES.ROOT);
    }
  }, [router, user]);

  return (
    <>
      <ErrorDialog
        title={errorTitle}
        message={errorMessage}
        initOpen={showErrorDialog}
      />
      <Container
        maxWidth={false}
        sx={{ display: "grid", minHeight: "100vh", background: "#0F492B" }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", height: "100%" }}
        >
          <Grid item xs={12} sm={12}>
            <FormProvider {...methods}>
              <Grid
                container
                sx={{
                  py: "6rem",
                  px: "1rem",
                }}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  rowSpacing={5}
                  sx={{
                    maxWidth: { sm: "100rem" },
                    marginInline: "auto",
                  }}
                >
                  <Grid item xs={12} sm={6}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      component="form"
                      noValidate
                      autoComplete="off"
                      sx={{ paddingRight: { sm: "3rem" } }}
                      onSubmit={methods.handleSubmit(onSubmitHandler)}
                    >
                      <FormHeader variant="h4">Log In</FormHeader>

                      <FormInput
                        type="email"
                        name="email"
                        placeholder="john@gmail.com"
                        label="Email"
                        size="medium"
                        InputLabelProps={{
                          style: { color: theme.palette.text.secondary },
                        }}
                        focused
                      />
                      <FormInput
                        type="password"
                        name="password"
                        placeholder="**********"
                        label="Password"
                        size="medium"
                        InputLabelProps={{
                          style: { color: theme.palette.text.secondary },
                        }}
                        focused
                      />
                      <ForgotPasswordContainer>
                        <Typography
                          variant="body1"
                          sx={{
                            textAlign: "right",
                            mb: "1.5rem",
                            color: "white",
                          }}
                        >
                          <Link href={ROUTES.FORGOT_PASSWORD}>
                            Forgot your password?
                          </Link>
                        </Typography>
                      </ForgotPasswordContainer>
                      <LoadingButton
                        loading={loadingState}
                        type="submit"
                        variant="contained"
                        size="large"
                        fullWidth
                        color="secondary"
                      >
                        Sign in
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </FormProvider>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Login;
