import { theme } from "@/theme/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";

const ErrorDialog = ({
  title,
  message,
  initOpen,
}: {
  title: string;
  message: string;
  initOpen: boolean;
}) => {
  const [open, setOpen] = useState(initOpen);

  useEffect(() => {
    setOpen(initOpen);
  }, [initOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ background: "white", color: "black" }}>
          <DialogTitle sx={{ color: "black" }} id="alert-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ color: theme.palette.grey[800] }}
              id="alert-dialog-description"
            >
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default ErrorDialog;
